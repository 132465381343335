import { PageTypes } from "./types";
import { APP_PATHS } from "./constants";

export const getPageLink = ({
  pageType,
  searchParams,
  params,
  url,
}: {
  pageType: PageTypes;
  params?: string | null;
  searchParams?: { [key: string]: string | string[] | undefined };
  url?: string | null;
}) => {
  if (pageType === "external") return url ?? "";
  const pathname = [APP_PATHS[pageType], params]
    .filter(Boolean)
    .join("/")
    .replaceAll("//", "/");
  const query = getQueryParamsString(searchParams);
  const href = [pathname, query].filter(Boolean).join("?");
  return href;
};

const getQueryParamsString = (record?: {
  [key: string]: string | string[] | undefined;
}): string => {
  const queryParams = new URLSearchParams();

  Object.entries(record ?? {}).forEach(([attr, value]) => {
    if (Array.isArray(value)) value.forEach((v) => queryParams.append(attr, v));
    else if (value) queryParams.append(attr, value);
  });

  return queryParams.toString();
};
