import { SanityIcon } from "@/cms/icons/Icon";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import classNames from "classnames";
import { CSSProperties, FC, type JSX } from "react";
import { getPageLinkFromLinkConfig } from "../selectors";
import { getTrackingDataset } from "@/tracking/utils";
import { ContentData } from "@/cms";
import { Link, Anchor, LinkButton } from "@/navigation";
import { Link as LinkType } from "../types";
import { LinkQueryResult } from "@/cms/sanity.types";
import { UITrackData } from "@/tracking/ui-event";

/**
 * @deprecated
 * Should instead load data using linkQueryResult
 */
export const mapLinkConfigToQueryResult = (
  config: LinkType,
): NonNullable<LinkQueryResult> =>
  config as unknown as NonNullable<LinkQueryResult>;

export type LinkProps = ContentData<
  Omit<NonNullable<LinkQueryResult>, "variant">
> &
  JSX.IntrinsicElements["a"] & {
    data: {
      variant?: "custom" | NonNullable<LinkQueryResult>["variant"];
    };
  };

const getLinkComponentFromVariant = (variant: LinkProps["data"]["variant"]) => {
  if (variant === "button") return LinkButton;
  if (variant === "link") return Link;
  return Anchor;
};

export const CMSLink: FC<LinkProps> = ({
  data: {
    variant,
    theme,
    cartItems,
    contentAlignment,
    icon,
    title,
    microcopy,
    ...link
  },
  className,
  children,
  ...rest
}) => {
  const href = getPageLinkFromLinkConfig({
    link: { ...link, cartItems },
  });
  const tracking = getTrackingDataset<UITrackData>({
    ui: {
      item_type: variant ?? "",
      item_id: link.slug ?? "unknown-link-id",
      item_name: "link",
    },
  });

  const LinkComponent = getLinkComponentFromVariant(variant);

  return (
    <div>
      <LinkComponent
        {...tracking}
        {...rest}
        href={href}
        className={classNames(
          {
            "bg-primary cvr-button border-2 border-[unset] text-[var(--fg-primary)] no-underline! shadow-none duration-150 ease-in-out hover:border-2 hover:bg-[var(--hover)] hover:text-[var(--fg-primary)]":
              variant === "button",
            "inline-flex items-center gap-1 underline underline-offset-8 hover:no-underline focus:rounded-lg focus:border-4 focus:border-black":
              variant === "link",
            "border-primary-contrast": variant === "button" && theme,
            "text-primary-contrast border-2 border-[unset] shadow-none hover:border-2 hover:bg-[var(--hover)] hover:text-[unset]":
              variant === "button" && !theme,
          },
          className,
        )}
        style={
          {
            "--text-hover": `color-mix(in srgb, var(--text-primary-contrast) 80%, black)`,
            "--hover": `color-mix(in srgb, var(--bg-primary) 80%, black)`,
            ...(variant === "button" && theme
              ? getCSSPropertiesTheme(theme)
              : {}),
          } as CSSProperties
        }
      >
        <div
          className={classNames("flex gap-2", {
            "flex-row-reverse justify-end": contentAlignment === "left",
          })}
        >
          {title && microcopy ? `${title} *` : title}
          {icon && <SanityIcon data={{ ...icon }} size={24} />}
        </div>
        {children}
      </LinkComponent>
      {microcopy ? <p>{`* ${microcopy}`}</p> : null}
    </div>
  );
};

export default CMSLink;
