import {
  PiDiceOne,
  PiDiceFive,
  PiDiceFour,
  PiDiceSix,
  PiDiceThree,
  PiDiceTwo,
  PiSquare,
} from "react-icons/pi";
import type { IconBaseProps as IconProps } from "react-icons";
import type { FC, JSX } from "react";
import { Dice as DiceTypes } from "@/cms/types/objects";
import classNames from "classnames";

export const Dice: FC<
  { value: 1 | 2 | 3 | 4 | 5 | 6 | number | null } & IconProps
> = ({ value, ...rest }) => {
  switch (value) {
    case 1:
      return <PiDiceOne aria-label="Terningkast 1" {...rest} />;
    case 2:
      return <PiDiceTwo aria-label="Terningkast 2" {...rest} />;
    case 3:
      return <PiDiceThree aria-label="Terningkast 3" {...rest} />;
    case 4:
      return <PiDiceFour aria-label="Terningkast 4" {...rest} />;
    case 5:
      return <PiDiceFive aria-label="Terningkast 5" {...rest} />;
    case 6:
      return <PiDiceSix aria-label="Terningkast 6" {...rest} />;
    default:
      return <PiSquare {...rest} />;
  }
};

export const getDiceValue = (string: string) => {
  if (string === "PiDiceSix") return 6;
  if (string === "PiDiceFive") return 5;
  if (string === "PiDiceFour") return 4;
  return null;
};

export type DiceQuoteProps = {
  value: DiceTypes;
};

export const DiceQuote: FC<
  DiceQuoteProps & JSX.IntrinsicElements["blockquote"]
> = ({ value: { icon, text, textAlignment }, className, ...rest }) => {
  return (
    <blockquote
      className={classNames(className, "mx-auto flex w-fit gap-x-2 sm:mx-0")}
      data-testid="dice"
      {...rest}
    >
      <span
        className={classNames("inline-block w-fit self-center sm:text-left")}
      >
        {text}
      </span>
      <Dice
        className={classNames("inline-block min-w-fit self-center", {
          "order-first": textAlignment === "after",
        })}
        value={getDiceValue(icon)}
        size={44}
      />
    </blockquote>
  );
};
