import { UITrackData } from "@/tracking/ui-event";
import { getTrackingDataset } from "@/tracking/utils";
import Link, { LinkProps } from "next/link";

import type { JSX } from "react";

export type AnchorProps = LinkProps &
  Omit<JSX.IntrinsicElements["a"], keyof LinkProps>;

export const Anchor = ({ href, children, ...rest }: AnchorProps) => {
  const rel =
    typeof href === "string" && href.startsWith("https://")
      ? "noreferer noopen"
      : undefined;

  const trackingProps = getTrackingDataset<UITrackData>({
    ui: {
      item_type: "link",
      item_id: `link-${href.toString()}`,
      item_name: "link",
    },
  });

  return (
    <Link {...trackingProps} {...rest} href={href} rel={rel}>
      {children}
    </Link>
  );
};
