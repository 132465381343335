export const APP_PATHS = {
  home: "/",
  page: "/",
  article: "/artikkel",
  product: "/produkter",
  book: "/boker",
  contributor: "/forfatter",
  subscription: "/abonnement",
  checkout: "/utsjekk",
  receipt: "/kvittering",
  cookieInformation: "/cookieInformation",
  "my-page": "/min-side",
} as const;
