import { IconListBlock } from "./IconListBlock/IconListBlock";
import { ValuePropositionList } from "./ValuePropositionList.tsx/ValuePropositionList";
import { QuoteBlock } from "./QuoteBlock/QuoteBlock";
import { Quote } from "../components/Quote";
import { ImageBoxBlock } from "./ImageBlock/ImageBoxBlock";
import { LinkBlock } from "./Link/LinkBlock";
import { DiceQuoteBlock } from "./DiceBlock/DiceBlock";
import { Nullable } from "@/types";
import {
  PortableTextProps,
  PortableText as PortableTextComponent,
} from "next-sanity";

export const getPortableTextComponentsBlock = (
  isHero: boolean,
): PortableTextProps["components"] => {
  const headingBaseLevel = isHero ? 0 : 1;
  const {
    H1,
    H2,
    H3,
    H4,
  }: Record<"H1" | "H2" | "H3" | "H4", "h1" | "h2" | "h3" | "h4" | "h5"> = {
    H1: `h${headingBaseLevel + 1}` as "h1" | "h2",
    H2: `h${headingBaseLevel + 2}` as "h2" | "h3",
    H3: `h${headingBaseLevel + 3}` as "h3" | "h4",
    H4: `h${headingBaseLevel + 4}` as "h4" | "h5",
  };
  return {
    block: {
      h1: ({ children }) => (
        <H1 className="heading-01 leading-[1.2]">{children}</H1>
      ),
      h2: ({ children }) => (
        <H2 className="heading-02 leading-[1.2]">{children}</H2>
      ),
      h3: ({ children }) => (
        <H3 className="heading-03 leading-[1.2]">{children}</H3>
      ),
      h4: ({ children }) => (
        <H4 className="heading-04 leading-[1.2]">{children}</H4>
      ),
      blockquote: ({ children }) => (
        <Quote
          value={{
            text: children?.toString() ?? "",
            variant: "indented",
          }}
        />
      ),
    },
  };
};

export type BlockVariableAnnotations = Nullable<{
  customerName: string;
  productName: string;
}>;

export type PortableTextValue = Pick<PortableTextProps, "value">["value"];
export type PortableTextSectionProps = Pick<PortableTextProps, "value"> & {
  blockVariableAnnotations?: BlockVariableAnnotations;
  isHero?: boolean;
};

export const PortableText = ({
  blockVariableAnnotations,
  isHero = false,
  ...rest
}: PortableTextSectionProps) => {
  return (
    <PortableTextComponent
      {...rest}
      components={{
        ...getPortableTextComponentsBlock(isHero),
        marks: {
          variable: ({ value: { variable } }) => {
            if (blockVariableAnnotations) {
              //for some reason this gets a bunch of insvisible white space characters ?
              const variableWithoutWhitespace = variable.replace(
                /[\u200B-\u200D\uFEFF]/g,
                "",
              );
              return (
                <>
                  {blockVariableAnnotations[
                    variableWithoutWhitespace as keyof BlockVariableAnnotations
                  ] ?? null}
                </>
              );
            }
            return <></>;
          },
        },
        types: {
          valuePropositionList: ValuePropositionList,
          iconList: IconListBlock,
          quote: QuoteBlock,
          dice: DiceQuoteBlock,
          imageBlock: ImageBoxBlock,
          linkConfig: LinkBlock,
        },
      }}
    />
  );
};
