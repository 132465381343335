import CMSLink from "@/cms/links/components/CMSLink";
import { LinkQueryResult } from "@/cms/sanity.types";
import { PortableTextBlock, PortableTextTypeComponent } from "next-sanity";

export const LinkBlock: PortableTextTypeComponent<
  LinkQueryResult & PortableTextBlock
> = ({ value }) => {
  return (
    <CMSLink
      data={value}
      className="inline-flex w-fit self-center sm:self-start"
    />
  );
};
